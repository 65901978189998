// @flow
import React from "react";
import { Box, Button } from "@mui/material";
import { EventPostback } from "@fas/cpa-cabinet-ui";
import type { EventPostbackType } from "@fas/cpa-cabinet-ui/lib/EventPostback/EventPostback.types";
import { makeStylesTyped } from "../../helpers/generators";
import type { Classes } from "./types/EventPostbackComponent.types";

export const eventTypeOptions = [
  {
    title: "Info",
    value: "info",
  },
  {
    title: "Negative",
    value: "negative",
  },
];

const useStyles: () => Classes = makeStylesTyped((theme) => ({
  addBtn: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    color: "#fff",
  },
  type: {
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      flexBasis: "17%",
    },
    "& *": {
      color: "#fff",
    },
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      flexBasis: "17%",
    },
    "& *": {
      color: "#fff",
    },
  },
  url: {
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      flexGrow: "1",
      maxWidth: "100%",
      flexBasis: "0",
    },
    "& *": {
      color: "#fff",
    },
    "& .MuiTextField-root": {
      background: theme.palette.mainBg,
    },
  },
  buttonDelete: {
    marginRight: theme.spacing(1),
    color: "#fff",
  },
}));

export const eventNameOptions = {
  info: [
    {
      title: "Registration",
      value: "registration",
    },
    {
      title: "Confirm",
      value: "confirm",
    },
    {
      title: "Payment",
      value: "payment",
    },
    {
      title: "Try",
      value: "try",
    },
  ],
  negative: [
    {
      title: "Chargeback",
      value: "chargeback",
    },
    {
      title: "Decline",
      value: "decline",
    },
  ],
};

type Props = {
  items: Array<EventPostbackType>,
  onChange: (Array<EventPostbackType>) => mixed,
}

const EventPostbackComponent = ({
  items,
  onChange,
}: Props) => {
  const classes: Classes = useStyles();
  const someEdited = items.some(({ isEditable }) => isEditable);
  const handleUpdateEventsPostback = (index: number, item: EventPostbackType) => {
    if (items[index].type !== item.type) {
      // eslint-disable-next-line no-param-reassign
      item.name = "";
    }
    onChange([...items.slice(0, index), item, ...items.slice(index + 1)]);
  };

  const handleAddEventsPostback = (item: EventPostbackType) => {
    onChange([...items, item]);
  };

  const handleSaveEventPostback = (index: number, item: EventPostbackType) => {
    onChange([...items.slice(0, index), item, ...items.slice(index + 1)]);
  };

  const handleDeleteEventPostback = (index: number) => {
    onChange([...items.slice(0, index), ...items.slice(index + 1)]);
  };
  return (
    <Box>
      {items.map((postback: EventPostbackType, index: number) => (
        <EventPostback
          key={postback.id}
          data={postback}
          onChange={(item: EventPostbackType): mixed => handleUpdateEventsPostback(index, item)}
          onSave={(item: EventPostbackType): mixed => handleSaveEventPostback(index, item)}
          onDelete={(): mixed => handleDeleteEventPostback(index)}
          disabled={someEdited}
          typeOptions={eventTypeOptions}
          nameOptions={eventNameOptions[postback.type] || []}
          classes={{
            button: `MuiButton-contained MuiButton-containedPrimary ${classes.addBtn}`,
            type: classes.type,
            name: classes.name,
            url: classes.url,
            buttonDelete: classes.buttonDelete,
          }}
        />
      ))}

      { items.length < 5 && (
        <Box pt={2}>
          <Button
            disabled={someEdited}
            className={`MuiButton-contained MuiButton-containedPrimary ${classes.addBtn}`}
            onClick={() => handleAddEventsPostback({
              id: Math.random().toString(16).slice(2),
              name: "",
              type: "",
              url: "",
              isEditable: true,
            })}
            data-testid="event-postback-add-button"
          >
            Add events postback
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EventPostbackComponent;
